.form-check-input:checked {
  background-color: #068a55;
}
.form-check-input:disabled {
  opacity: 1;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  opacity: 1;
}
