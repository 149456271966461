.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 1090 !important;
}

.qr-modal {
  z-index: 1090 !important;
}

.ReactModal__Content.ReactModal__Content--after-open.qr-modal {
  height: 100%;
}
