.ant-select-selector {
    border: 0 !important;
}

.ant-select-focused {
    border: 0 !important;
}

.ant-select-tree-title,
.ant-select-selection-item {
    font-size: 18px;
}
